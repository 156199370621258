<template>
  <div class="map-container">
    <VehicleLiveTracking
      v-if="showMap"
      ref="leafletMap"
      :channel-name="dashboadData.tenant_channel_name"
      :product="options.product"
      :vehicles="vehiclesForMap()"
      class="map"
    >
      <div
        ref="controlBox"
        class="control-box"
      >
        <div
          v-if="!isLoading"
          class="mapTabsWrap"
        >
          <v-tabs
            v-model="tab"
            class="customTab"
          >
            <div
              class="moveable"
              @mousedown.stop="startDrag"
              @touchstart.stop="startDrag"
              @mouseup="endDrag"
              @touchend="endDrag"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </div>
            <div class="d-flex justify-space-between">
              <v-tab
                v-for="item in items"
                :key="item.tab"
              >
                {{ item.tab }}
              </v-tab>
              <div
                class="toggle-btn"
                @click="isControl = !isControl"
              >
                <v-icon>
                  {{
                    isControl ? "mdi-arrow-up-thick" : "mdi-arrow-down-thick"
                  }}
                </v-icon>
              </div>
            </div>
          </v-tabs>
          <transition name="slide-fade">
            <v-tabs-items
              v-if="isControl"
              v-model="tab"
            >
              <v-tab-item
                v-for="item in items"
                :key="item.tab"
              >
                <div v-if="item.content === 'allVehicles'">
                  <div class="vehicles-listing">
                    <div class="tabs-header top-filters">
                      <v-card class="searchField">
                        <v-text-field
                          v-model="searchVehicle"
                          label="Search"
                          filled
                          dense
                          clearable
                        />
                      </v-card>
                    </div>
                    <div
                      class="vehicles-items"
                      @wheel.stop
                    >
                      <div
                        v-for="vehicle in filteredVehicles"
                        :key="vehicle.vehicle_id"
                        class="vehicle-item"
                      >
                        <v-checkbox
                          :input-value="vehicle.selected"
                          :label="vehicle.registration_no || vehicle.chassis_no"
                          @click="addToMap(vehicle)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="item.content === 'groups'">
                  <div class="tabs-header top-filters">
                    <v-card class="searchField">
                      <v-text-field
                        v-model="searchGroup"
                        label="Search"
                        filled
                        dense
                        clearable
                      />
                    </v-card>
                  </div>
                  <div
                    class="groups-listing"
                    @wheel.stop
                  >
                    <div
                      v-for="group in groups"
                      :key="group.id"
                      class="group-item"
                    >
                      <div
                        class="group-header"
                        @click="toggleGroup(group.id)"
                      >
                        <v-checkbox
                          :input-value="group.selected"
                          :label="group.group_name"
                          @click.stop="toggleGroupSelection(group)"
                        />
                        <span class="groupTabAccordion">{{
                          isGroupOpen(group.id) ? "-" : "+"
                        }}</span>
                      </div>
                      <div
                        v-if="isGroupOpen(group.id)"
                        class="group-vehicles"
                      >
                        <div
                          v-for="vehicle in group.vehicles"
                          :key="vehicle.id"
                          class="vehicle-item"
                        >
                          <v-checkbox
                            :input-value="vehicle.selected"
                            :label="
                              vehicle.registration_no || vehicle.vehicle_name
                            "
                            @click="addToMap(vehicle)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="item.content === 'filters'">
                  <v-container class="top-filters">
                    <v-row>
                      <v-col
                        cols="6"
                        class="pb-0"
                      >
                        <!-- Ignition Dropdown -->
                        <v-select
                          v-model="ignitionValue"
                          :items="ignitionOptions"
                          label="Ignition"
                          dense
                          filled
                          clearable
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        class="pb-0"
                      >
                        <!-- Speed Dropdown -->
                        <v-select
                          v-model="speedValue"
                          :items="speedOptions"
                          item-value="id"
                          item-text="name"
                          label="Speed"
                          dense
                          filled
                          clearable
                        />
                      </v-col>
                      <v-col cols="6">
                        <!-- Movement Status Dropdown -->
                        <v-select
                          v-model="movementStatusValue"
                          :items="movementStatusOptions"
                          label="Movement Status"
                          dense
                          filled
                          clearable
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          color="primary"
                          class="m-0"
                          @click="applyFilters"
                        >
                          Apply
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </transition>
        </div>
      </div>
    </VehicleLiveTracking>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    VehicleLiveTracking: () =>
      import("@/components/sections/VueLeafLetMap/VehicleLiveTracking.vue"),
  },
  data() {
    return {
      controlPosition: { top: 16, left: 16 },
      dragging: false,
      offsetX: 0,
      offsetY: 0,

      isControl: true,
      options: {},
      showMap: false,
      activeTab: "allVehicles",
      searchVehicle: "",
      searchGroup: "",
      ignitionValue: null,
      ignitionOptions: ["On", "Off"],
      speedValue: null,
      speedOptions: [
        { id: 10, name: ">10" },
        { id: 20, name: ">20" },
        { id: 30, name: ">30" },
        { id: 40, name: ">40" },
        { id: 50, name: ">50" },
      ],
      movementStatusValue: null,
      movementStatusOptions: ["true", "false"],
      tab: 0,
      items: [
        { tab: "All Vehicles", content: "allVehicles" },
        { tab: "Groups", content: "groups" },
        { tab: "Filters", content: "filters" },
      ],
      //
      vList: null,
      //

      openGroups: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "vehicles/getIsLoadingVehicles",
      dashboadData: "dashboard/getDashboadSectionFour",
      vehicleList: "vehicles/getVehiclesList",
      groupList: "vehicles/getGroupListing",
    }),

    filteredVehicles() {
      let arr = this.vehicleList.filter((r) => r.lt && r.ln);

      if (this.searchVehicle) {
        // search
        const qry = this.searchVehicle;
        // Create a case-insensitive regex
        const regex = new RegExp(qry, "i");
        arr = arr.filter((r) => regex.test(r.dn));
      }

      // for Filters
      if (this.ignitionValue) {
        arr = arr.filter((r) => r.ign_s == this.ignitionValue);
      }
      if (this.movementStatusValue) {
        const mss = this.movementStatusValue == "true" ? true : false;
        arr = arr.filter((r) => r.ms == mss);
      }
      if (this.speedValue) {
        arr = arr.filter((r) => r.spd >= this.speedValue);
      }

      return arr;
    },

    groups() {
      let grp = [];
      this.groupList.forEach((el) => {
        if (el.vehicles.length > 0) {
          el.vehicles.forEach((v) => {
            v.device_id = v.flespi_device_id;
            v.vehicle_id = v.id;
          });

          el.vehicles = el.vehicles.filter((r) => r.device_id !== "N/A");
          if (el.vehicles.length > 0) {
            grp.push(el);
          }
        }
      });

      if (this.searchGroup) {
        // search
        const qry = this.searchGroup;
        // Create a case-insensitive regex
        const regex = new RegExp(qry, "i");
        grp = grp.filter((r) => regex.test(r.group_name));
      }

      return grp;
    },
  },
  mounted() {
    this.fetchVehicles();
  },
  methods: {
    // startDrag(e) {
    //   e.stopPropagation(); // Stop event from reaching Leaflet

    //   const box = this.$refs.controlBox;
    //   const offsetX = e.clientX - box.getBoundingClientRect().left;
    //   const offsetY = e.clientY - box.getBoundingClientRect().top;
    //   const onMouseMove = (e) => {
    //     box.style.left = `${e.clientX - offsetX}px`;
    //     box.style.top = `${e.clientY - offsetY}px`;
    //   };

    //   const onMouseUp = () => {
    //     document.removeEventListener("mousemove", onMouseMove);
    //     document.removeEventListener("mouseup", onMouseUp);
    //   };

    //   document.addEventListener("mousemove", onMouseMove);
    //   document.addEventListener("mouseup", onMouseUp);
    // },
    startDrag(e) {
      e.stopPropagation();

      const box = this.$refs.controlBox;
      const parent = box.parentElement;

      const offsetX = e.clientX - box.getBoundingClientRect().left;
      const offsetY = e.clientY - box.getBoundingClientRect().top;

      const parentRect = parent.getBoundingClientRect();
      // const boxRect = box.getBoundingClientRect();

      const onMouseMove = (e) => {
        let newLeft = e.clientX - offsetX;
        let newTop = e.clientY - offsetY;

        // Ensure the box stays within the parent boundaries
        const maxLeft = parentRect.width - box.offsetWidth;
        const maxTop = parentRect.height - box.offsetHeight;

        if (newLeft < 0) newLeft = 0;
        if (newTop < 0) newTop = 0;
        if (newLeft > maxLeft) newLeft = maxLeft;
        if (newTop > maxTop) newTop = maxTop;

        box.style.left = `${newLeft}px`;
        box.style.top = `${newTop}px`;
      };

      const onMouseUp = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },

    endDrag() {
      // No need to disable/enable map dragging if we stop propagation properly
    },
    async fetchVehicles() {
      if (!this.authUser.isAdmin) {
        this.options.tenant_id = this.authUser.independent_fleet_user_nature
          ? this.authUser.tenant_id
          : this.authUser.parent_tenant_id;
      }

      await this.$store.dispatch("vehicles/getVehiclesMQTT").then(() => {
        // this.resetMap();
      });
      await this.$store
        .dispatch("vehicles/vehicleGroupListing", {
          ...this.options,
          child_group_detail: 1,
        })
        .then(() => {});
      this.resetMap();
    },
    resetMap(val = 1000) {
      this.showMap = false;
      setTimeout(() => (this.showMap = true), val);
    },
    vehiclesForMap() {
      return this.filteredVehicles.filter((r) => r.selected);
    },
    addToMap(v) {
      // check box for Vehicle Tab
      this.filteredVehicles.forEach((el) => {
        if (el.vehicle_id == v.vehicle_id) {
          el.selected = !el.selected;
        }
      });

      // check box for Group Tab
      this.groups.forEach((el) => {
        el.vehicles.forEach((veh) => {
          if (v.vehicle_id == veh.vehicle_id) {
            veh.selected = !veh.selected;
          }
        });

        el.selected = el.vehicles.every((r) => r.selected);
      });
      // v.selected = !v.selected;

      this.resetMap(100);
    },
    toggleGroup(groupId) {
      if (this.openGroups.includes(groupId)) {
        this.openGroups = [];
      } else {
        this.openGroups = [groupId];
      }
    },
    isGroupOpen(groupId) {
      return this.openGroups.includes(groupId);
    },
    toggleGroupSelection(group) {
      group.selected = !group.selected;
      // group.vehicles.forEach((vehicle) => {
      //   vehicle.selected = group.selected;
      // });
      const ids = group.vehicles.map((r) => r.id);
      this.filteredVehicles.forEach((el) => {
        if (ids.includes(el.vehicle_id)) {
          el.selected = !el.selected;
        }
      });

      this.groups.forEach((el) => {
        const ch = [];
        el.vehicles.forEach((v) => {
          if (ids.includes(v.vehicle_id)) {
            v.selected = !v.selected;
          }
          ch.push(v.selected);
        });
        el.selected = ch.every((r) => r);
      });

      this.resetMap();
    },

    applyFilters() {
      this.resetMap();
    },
  },
};
</script>

<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
#map
  height: 94vh !important

.map-container
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  // position: relative

.mapTabsWrap
  // position: ab/solute
  // right: 15px
  // top:30px
  max-width: 340px
  min-width: 340px
  z-index: 99
  background: rgba(255,255,255,0.85)

.tabs-header
  padding: 1rem
  display: flex
  flex-wrap: wrap

.searchField
  width: 100%

.vehicles-items
  display: flex
  flex-wrap: wrap
  margin: 0rem 1rem 1rem 1rem
  overflow: hidden
  overflow-y: auto
  max-height: 300px
  .vehicle-item
    display: flex
    flex-wrap: wrap
    background: #f3f3f3
    width: 100%
    padding: 0.5rem 0.75rem
    margin-right: 0.75rem
    margin-bottom: 0.25rem
    :deep .v-input
      margin-top: 0 !important
      .v-input__control
        .v-input__slot
          margin: 0 !important
          .v-label
            font-size: 0.9375rem
            font-family: $poppinReg

        .v-messages
          display: none !important

.groups-listing
  display: flex
  flex-wrap: wrap
  margin: 0rem 1rem 1rem 1rem
  overflow: hidden
  overflow-y: auto
  max-height: 300px
  .group-item
    display: flex
    flex-wrap: wrap
    width: 100%
    :deep .v-input
      margin-top: 0 !important
      .v-input__control
        .v-input__slot
          margin: 0 !important
          .v-label
            font-size: 0.9375rem
            font-family: $poppinReg

        .v-messages
          display: none !important

  .group-header
    display: flex
    flex-wrap: wrap
    width: 100%
    background: #f3f3f3
    padding: 0.5rem 0.75rem
    margin-right: 0.75rem
    margin-bottom: 0.25rem
    .groupTabAccordion
      margin-left: auto
      font-size: 1.25rem
      line-height: 1.75rem
      cursor: pointer
  .group-vehicles
    display: flex
    flex-wrap: wrap
    width: 100%
    padding: 0 1rem 1rem
    margin-top: 0.5rem
    .vehicle-item
      display: flex
      flex-wrap: wrap
      background: #f3f3f3
      width: 100%
      padding: 0.5rem 0.75rem
      margin-bottom: 0.25rem

:deep .v-messages
  display: none !important
:deep .v-text-field__details
  display: none !important

.toggle-btn
  cursor: pointer
  display: flex
  align-items: center
  padding-right: 0.5rem
  &:hover
    i
      color: #20a390



.control-box
  position: absolute
  top: 1rem
  right: 1rem
  z-index: 9999
  cursor: grab
  user-select: none
  transition: none

.control-box:active
  cursor: grabbing

.moveable
  cursor: move
  position: absolute
  right: 0
  height: 100%
  display: flex
  align-items: center
</style>
